.formLabel{
    text-align: left;
    margin-left: 10px;
    vertical-align: middle;
    padding-top: 5px;
}

.formLabelName{
    text-align: left;
}

.form{
    display: grid;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    justify-content: center;
}

.form-group{
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;    
    padding-right: 50px;
    align-self: center;
	width: 100%;
}

.request{    
    min-height: 6rem;
}

.warningMessage{
    margin-bottom: 3rem;
    font-family:Lucida Sans Unicode;
}

.submitButton{
    width: 125px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
}

.captcha{
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.form-group-name{
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;    
    padding-right: 50px;
    align-self: center;
	width: 50%;
}

@media only screen and (max-width: 768px){
	.row {
		display: block !important;
	}
}
